import React, { useState, useEffect, useMemo } from "react";
import { Link } from "gatsby";
import Image from "@common/Image/Image";
import Button from "@common/Button/Button";
import CircleBLur from "@common/CircleBlur/CircleBlur";
import MainHeading from "@common/MainHeading/MainHeading";
import { TABLET_MAX_WIDTH, TABLET_MIN_WIDTH } from "@constants";
import useWindowDimensions from "@hooks/useWindowDimensions";
import "./CaseStudies.scss";
import shellLogo from "@images/case-studies/shell-logo.svg"
import shellPreview from "@images/case-studies/shell-preview.png"
import shellMobilePreview from "@images/case-studies/shell-mobile.svg"

const CaseStudies = ({ data }) => {
    const [mainImage, setMainImage] = useState();
    const { width } = useWindowDimensions();
    const isDesktop = width > TABLET_MAX_WIDTH;
    const isMobile = width < TABLET_MIN_WIDTH;
    const isTablet = !isMobile && width <= TABLET_MAX_WIDTH;

    let sortedCases = useMemo(
        () =>
            data.allStrapiCaseStudies.nodes.sort(
                (a, b) => new Date(a.order) - new Date(b.order)
            ),
        [data]
    );

    sortedCases = sortedCases.filter(caseStudy => caseStudy.order !== 0);

    useEffect(() => {
        if (isMobile) {
            setMainImage(shellMobilePreview);
        } else {
            setMainImage(shellPreview);
        }
    }, [isMobile, isTablet]);

    return (
        <div className="case-studies">
            <MainHeading
                heading="Case Studies"
                mainParagraph="Impressit team works hard to make the world a better place
                and build a brighter future. The stories of successful cooperation
                with our clients inspire and drive us every day.
                Results speak louder than words and will tell you more than any sales pitch. 
                That’s why we proudly present the series of our case studies, 
                so you can see for yourself how working with Impressit is the best choice."
            />
            <div
                style={{ backgroundImage: `url("${mainImage}")` }}
                className="case-studies__main-product"
            >
                <div className="case-studies__main-product-about">
                    <Image
                        src={shellLogo}
                        className="case-studies__main-product-logo"
                        alt="Our main product logo"
                        isLazy={false}
                    />
                    <h2>
                    Team augmentation for the 
                    <br />
                    multinational oil and gas company
                    </h2>
                    <div className="case-studies__product-info">
                        <p>Our contribution</p>
                        <strong>Frontend and backend development</strong>
                    </div>
                    <div className="case-studies__product-info">
                        <p>Industry</p>
                        <strong>Oil and Gas</strong>
                    </div>
                    <div className="case-studies__product-info case-studies__product-info-technologies">
                        <p>Technologies</p>
                        <strong>
                        React.js, Python, Node.js, Azure DevOps
                        </strong>
                    </div>
                    <Link to="/case-studies/shell">
                        <Button text="Dive in" />
                    </Link>
                </div>
            </div>
            {sortedCases.map((caseStudy, index) => (
                <div className="case-studies__other-product" key={index}>
                    <div className="case-studies__product-about">
                        <Image
                            src={caseStudy.logo.url}
                            alt={`${caseStudy.metaUrl} product logo`}
                        />
                        <h2>{caseStudy.title}</h2>
                        <div className="case-studies__product-info">
                            <p>Our contribution</p>
                            <strong>{caseStudy.weMade}</strong>
                        </div>
                        <Link to={`/case-studies/${caseStudy.metaUrl}`}>
                            <Button text="Dive in" />
                        </Link>
                    </div>
                    <div className="case-studies__image-container">
                        <Image
                            className="case-studies__product-info-image"
                            src={caseStudy.coverImage.url}
                            alt={`${caseStudy.metaUrl} product`}
                        />
                        <CircleBLur
                            showOnMobile
                            top={isDesktop ? "25%" : "5%"}
                            left="15%"
                            width="350px"
                            height="350px"
                            blur="blur(100px)"
                            color={`linear-gradient(#${caseStudy.backlightColor},#${caseStudy.backlightColor}`}
                        />
                    </div>
                </div>
            ))}
            {isDesktop && (
                <div className="case-studies__how-do-we-do-that">
                    <h3>How do we do that?</h3>
                    <p>
                        We either build products for our clients or provide
                        dedicated teams that share their expertise
                        <br /> and help to build and grow the business
                    </p>
                    <p>
                        <Link to="/product-development">
                            About how we build products
                        </Link>{" "}
                        or{" "}
                        <Link to="/dedicated-teams">
                            how we create dedicated teams
                        </Link>
                    </p>
                </div>
            )}
        </div>
    );
};

export default CaseStudies;
